/*$pagination-color: #000000;
$pagination-hover-color: $MainColor;
$pagination-hover-border-color: $MainColor;
$pagination-hover-bg: none;
$pagination-active-color: $MainColor;
$pagination-active-bg: none;
$pagination-active-border-color: $MainColor;*/
/*for later use when build a table content using bootstrap col classes so different rows won't get problem with different length of word or content*/
.table-responsive .row {
  flex-wrap: nowrap !important;
}
.table-responsive .row .col, .table-responsive .row [class*=col-] {
  overflow-wrap: break-word !important;
}

.input-group {
  direction: ltr;
}

.form-row label {
  direction: ltr;
}

.form-row > .col, .form-row > [class*=col-] {
  text-align: left;
}

.form-row label {
  direction: ltr;
}

.form-group label {
  direction: ltr;
}

.form-row > .col, .form-row > [class*=col-] {
  text-align: left;
}

.HorizontalScrollContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0px !important;
}
.HorizontalScrollContainer > div:first-child {
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  direction: ltr;
  width: 100%;
  height: 100%;
  padding-bottom: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}
.HorizontalScrollContainer > div > div {
  direction: rtl;
}
.HorizontalScrollContainer > div .Item {
  float: left;
}
.HorizontalScrollContainer .ScrollBack, .HorizontalScrollContainer .ScrollNext {
  position: absolute;
  text-align: center;
}

.VerticalScrollContainer {
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 0px !important;
}
.VerticalScrollContainer > div:first-child {
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  direction: ltr;
  width: 100%;
  height: 100%;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}
.VerticalScrollContainer > div > div {
  direction: rtl;
}
.VerticalScrollContainer > div .Item {
  float: left;
}
.VerticalScrollContainer .ScrollBack, .VerticalScrollContainer .ScrollNext {
  position: absolute;
  text-align: center;
}

/*responsive grouping*/
.container-fluid {
  max-width: 1510px;
}

/*@import "partial/fonts";*/
.ClassName {
  width: 10px !important;
}

@media (min-width: 576px) {
  .ClassName {
    width: 20px !important;
  }
}
@media (min-width: 768px) {
  .ClassName {
    width: 30px !important;
  }
}
@media (min-width: 992px) {
  .ClassName {
    width: 40px !important;
  }
}
* {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-paging-area {
  padding-bottom: 2rem;
}

.padding-top > div[id=page-index] {
  padding-right: 0;
  padding-left: 0;
}
.padding-top > div[id^=page-]:not([data-path=pages]) {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-weight: 500;
  color: inherit;
  font-family: Roboto, sans-serif;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1.125rem;
}

.page-container {
  background-color: #ededed;
}

.btn-link {
  color: #2d72a4;
}

.btn-primary-light {
  color: #2d72a4;
}

.navbar {
  transition: 0.5s ease-in-out;
  padding-top: 18px;
  padding-bottom: 18px;
}
.navbar.bg-dark {
  background-color: #000000 !important;
}
.navbar.bg-dark .dropdown-menu {
  background-color: #000000 !important;
}
.navbar .nav-link {
  color: #fff !important;
}
.navbar .user-image-wrapper .standard-image {
  background: #363636 !important;
}

#tophero .box .svgicon, #tophero .homebox1 .svgicon, #homebox1 .box .svgicon, #homebox1 .homebox1 .svgicon, #homebox3 .box .svgicon, #homebox3 .homebox1 .svgicon {
  height: 65px;
  width: 65px;
  margin: 0 auto;
  color: #ffffff !important;
  border-radius: 50%;
  display: block;
  line-height: 0;
  border-width: 0px;
  flex-shrink: 0;
}
#tophero .box .svgicon .svg-link, #tophero .homebox1 .svgicon .svg-link, #homebox1 .box .svgicon .svg-link, #homebox1 .homebox1 .svgicon .svg-link, #homebox3 .box .svgicon .svg-link, #homebox3 .homebox1 .svgicon .svg-link {
  color: #ffffff !important;
}
#tophero .box .svgicon .svg-content, #tophero .homebox1 .svgicon .svg-content, #homebox1 .box .svgicon .svg-content, #homebox1 .homebox1 .svgicon .svg-content, #homebox3 .box .svgicon .svg-content, #homebox3 .homebox1 .svgicon .svg-content {
  fill: #ffffff;
  width: 0;
  height: 0;
}
#tophero .box .svgicon svg:not(:root), #tophero .homebox1 .svgicon svg:not(:root), #homebox1 .box .svgicon svg:not(:root), #homebox1 .homebox1 .svgicon svg:not(:root), #homebox3 .box .svgicon svg:not(:root), #homebox3 .homebox1 .svgicon svg:not(:root) {
  overflow: hidden;
}

#homebox3 .box .svgicon {
  fill: #478ac9;
  width: 50px;
  height: 50px;
  padding: 10px;
  color: #478ac9 !important;
  background-color: #ffffff;
}
#homebox3 .box .svgicon .svg-content {
  fill: #478ac9;
}

#homebox1 {
  padding-top: 35px;
  padding-bottom: 20px;
}
#homebox1 .homebox1 .svgicon {
  background-color: #425465;
  padding: 15px;
  float: left;
}
#homebox1 .homebox1 .textbox {
  float: left;
}
#homebox1 .homebox1 .textbox p {
  padding-top: 5px;
}

#homebox2 {
  background-color: #f2f2f2;
}
#homebox2 .row .col:first-child {
  background-image: url("/assets/auroraboreale/images/lamp-1209278_1920.jpg");
  background-size: cover;
  position: relative;
}
#homebox2 .row .col:first-child div {
  justify-content: center;
  color: #fff;
  background-color: rgba(240, 167, 65, 0.5215686275);
  opacity: 1;
  background-image: none;
  position: absolute;
  text-transform: uppercase;
  font-weight: 700;
  text-shadow: 2px 2px #434343;
  word-wrap: break-word;
  align-self: flex-start;
  font-family: Roboto, sans-serif;
}
#homebox2 .row .col:last-child {
  position: relative;
}
#homebox2 .row .col:last-child div {
  justify-content: center;
  color: #111111;
  background-color: #F0A741;
  opacity: 1;
  background-image: none;
  position: absolute;
  font-weight: 700;
  word-wrap: break-word;
  align-self: flex-start;
  font-family: "Open Sans", sans-serif;
}
#homebox2 .row .col:last-child div a {
  border-style: solid;
  text-transform: uppercase;
  border-color: #404040 !important;
  color: #404040 !important;
  background-color: transparent !important;
  stroke: none;
  flex-shrink: 0;
  display: table;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-decoration: none;
  outline-width: 0;
  font-style: initial;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  vertical-align: middle;
  text-align: center;
  border-radius: 0;
  align-self: flex-start;
}

#tophero {
  background-image: url(/assets/auroraboreale/images/bright-4357415_1920_2.jpg);
  color: #111111;
  text-align: center;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: -87px;
}
#tophero .row {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
#tophero .row > div {
  margin-top: 15px;
  margin-bottom: 15px;
}
#tophero .box {
  box-shadow: 5px 5px 20px 0 rgb(0, 0, 0);
  background-color: transparent !important;
  opacity: 1;
  display: flex;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  pointer-events: initial;
  color: #111111;
  background-color: #ffffff;
  padding: 30px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}
#tophero .box h3 {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 19px auto 0;
  padding: 0;
  color: #ffffff !important;
  position: relative;
  flex-shrink: 0;
  display: table;
  align-self: flex-start;
  line-height: 1.2;
  font-family: Roboto, sans-serif;
}
#tophero .box a {
  border-width: 2px;
  background-image: none;
  border-style: none none solid;
  font-weight: 700;
  margin: 21px auto 0;
  padding: 0;
  border-color: #ffffff !important;
  color: #ffffff !important;
  background-color: transparent !important;
  stroke: none;
  flex-shrink: 0;
  position: relative;
  border-radius: 0 !important;
  display: table;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  text-decoration: none;
  border: 0 none transparent;
  outline-width: 0;
  font-style: initial;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  vertical-align: middle;
  text-align: center;
  align-self: flex-start;
}
#tophero .box:before {
  color: #111111;
  background-color: #ffffff;
  opacity: 0.3;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#tophero .container {
  max-width: 885px;
}

.container {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.container.my-5 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  max-width: unset !important;
}

#homebox3 {
  font-weight: 400;
  color: #fff;
  text-align: center;
}
#homebox3 .text1 {
  padding-top: 50px;
}
#homebox3 .text3 {
  padding-top: 10px;
}

.web-footer {
  color: #ffffff;
  background-color: #333333;
  padding: 3rem 0;
  padding-bottom: 1rem;
}
.web-footer .footer-group-label {
  color: #ffffff;
  font-weight: bold;
}
.web-footer a {
  color: #ffffff;
}

.homeboxproduct {
  background-color: #f2f2f2;
  padding-top: 30px;
  padding-bottom: 30px;
}
.homeboxproduct .row {
  padding-top: 10px;
  padding-bottom: 10px;
}
.homeboxproduct .row .col > a {
  position: relative;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.homeboxproduct .row .col > a:hover .txt2 {
  background-color: #2d72a4;
  color: #fff;
}
.homeboxproduct .row .col .img {
  background-color: #fff;
  background-size: cover;
  background-position: center;
  margin-bottom: 60px;
}
.homeboxproduct .row .col .txt2 {
  position: absolute;
  bottom: 0px;
  width: 100%;
  color: #333;
  word-wrap: break-word;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.2;
  font-family: Roboto, sans-serif;
  text-align: center;
  padding: 25px;
  display: block;
  transition: all 0.3s;
  -webkit-appearance: none;
  text-decoration: none;
  background: #d4d3d3;
  /*didnot use the strech after becase the a its self is absolout we cannot 
      streach it to the full block so we but the a href on the main block*/
}
.homeboxproduct .row .col .txt1, .homeboxproduct .row .col .txt3 {
  padding: 20px;
  word-wrap: break-word;
  font-weight: 400;
  line-height: 1.2;
  font-family: Roboto, sans-serif;
  transition: all 0.3s;
  -webkit-appearance: none;
  white-space: pre-line;
}
.homeboxproduct .row .col.title {
  transition: all 0.3s;
  -webkit-appearance: none;
  background: #d4d3d3;
  margin-top: 10px;
}
.homeboxproduct .row .col.title .txt2 {
  padding-top: 30px;
  padding-left: 0px;
  text-align: left;
}

#homebox3 .parallax {
  /* The image used */
  background-image: url("/assets/auroraboreale/images/buildings-1839726_1920.jpg");
  /* Set a specific height */
  min-height: 500px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#accordion .card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0rem;
}
#accordion .btn-link {
  width: 100%;
}
#accordion .card-body > div {
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0px;
  border-bottom: 1px;
  border-color: rgb(218, 218, 218);
  border-style: solid;
}
#accordion .card-body > div > div {
  font-size: 12px;
}
#accordion .card-body > div > div::before {
  display: block;
  font-weight: bold;
}
#accordion .card-body > div > div:nth-child(1)::before {
  content: "Model: ";
}
#accordion .card-body > div > div.shape::before {
  content: "Width: ";
}
#accordion .card-body > div > div.power::before {
  content: "Power: ";
}
#accordion .card-body > div > div.cct::before {
  content: "CCT: ";
}
#accordion .card-body > div > div.cri::before {
  content: "CRI: ";
}
#accordion .card-body > div > div:nth-child(5)::before {
  content: "IP: ";
}
#accordion .card-body > div > div:nth-child(6)::before {
  content: "Beam Angle: ";
}
#accordion .card-body > div > div:nth-child(7)::before {
  content: "Luminous flux: ";
}
#accordion .card-body > div > div:nth-child(8)::before {
  content: "Color: ";
}
#accordion .card-body > div:nth-child(1) {
  border-color: #000;
}
#accordion .card-body .header > div {
  font-size: 12px;
}
#accordion .card-body .header > div::before {
  display: block;
  font-weight: bold;
}
#accordion .card-body .header > div:nth-child(1)::before {
  content: "Model";
}
#accordion .card-body .header > div.shape::before {
  content: "Width";
}
#accordion .card-body .header > div.power::before {
  content: "Power";
}
#accordion .card-body .header > div.cct::before {
  content: "CCT";
}
#accordion .card-body .header > div.cri::before {
  content: "CRI";
}
#accordion .card-body .header > div:nth-child(5)::before {
  content: "IP";
}
#accordion .card-body .header > div:nth-child(6)::before {
  content: "Beam Angle";
}
#accordion .card-body .header > div:nth-child(7)::before {
  content: "Luminous flux";
}
#accordion .card-body .header > div:nth-child(8)::before {
  content: "Color";
}
#accordion .card-body .pagination {
  margin-top: 10px;
  margin-bottom: 0px;
}
#accordion .card-body .pagination .page-item .page-link {
  cursor: pointer;
}
#accordion .card-body .pagination .page-item.active .page-link {
  cursor: unset;
  z-index: 1;
  color: #E75F25;
  background-color: unset;
  border-color: #E75F25;
}

.padding-top > div[id=page-index] {
  padding-right: 0;
  padding-left: 0;
}
.padding-top > div[id^=page-]:not([data-path=pages]) {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.homeboxproduct .row .col .img {
  height: 180px;
}
.homeboxproduct .row .col .txt1 {
  font-size: 1.4rem;
}
.homeboxproduct .row .col .txt3 {
  font-size: 1.2rem;
}
.homeboxproduct .row:hover .title {
  background-color: #2d72a4 !important;
  color: #fff;
}
.homeboxproduct .row:hover .txt3 {
  font-size: 1.4rem;
}

#tophero {
  padding-top: 90px;
  background-position: 70% 50%;
  height: 850px;
}

#homebox1 .homebox1 .textbox {
  padding-left: 15px;
  padding-top: 5px;
}
#homebox1 .homebox1 .textbox h5 {
  font-size: 0.9rem;
}
#homebox1 .homebox1 .textbox p {
  font-size: 0.8rem;
}

#homebox1 .homebox1 .svgicon {
  height: 40px;
  width: 40px;
  padding: 9px;
}

#homebox2 .row .col:first-child {
  height: 150px;
}
#homebox2 .row .col:first-child div {
  top: 10%;
  left: 0%;
  bottom: 10%;
  right: 0px;
  letter-spacing: 4px;
  line-height: 1;
  font-size: 2rem;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
#homebox2 .row .col:last-child {
  height: 170px;
}
#homebox2 .row .col:last-child div {
  top: 0%;
  left: 0%;
  bottom: 10%;
  right: 0px;
  letter-spacing: 4px;
  line-height: 1;
  font-size: 1.1rem;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
#homebox2 .row .col:last-child div a {
  border-width: 2px;
  letter-spacing: 2px;
  margin: 20px auto 0;
  padding: 10px 30px;
  font-size: 0.6em;
}

#homebox3 .box {
  padding: 20px;
}

#accordion .card-body > div > div {
  padding-right: 5px;
  padding-left: 5px;
}
#accordion .card-body > div:nth-child(1) {
  display: none;
}
#accordion .card-body > div > div::before {
  display: block;
  float: left;
  min-width: 90px;
}
#accordion .card-body > div:nth-child(1) > div::before {
  display: block;
}

.modal-dialog .detailsdialog .images .mainimage {
  height: 250px;
}
.modal-dialog .detailsdialog .images .iconimages .iconimage {
  height: 50px;
  width: 75px;
}
.modal-dialog .detailsdialog .nav-tabs .nav-item .nav-link {
  margin-right: 0rem;
  font-size: 0.65rem !important;
}

#homebox3 .text1 {
  font-size: 2rem;
  padding-top: 50px;
}
#homebox3 .text2 {
  font-size: 1.5rem;
}
#homebox3 .text3 {
  font-size: 1.125rem;
  padding-top: 10px;
}

.pagination .page-item .page-link {
  font-size: 0.7rem;
}

@media (min-width: 576px) {
  .padding-top > div[id=page-index] {
    padding-right: 0;
    padding-left: 0;
  }
  .padding-top > div[id^=page-]:not([data-path=pages]) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .homeboxproduct .row:hover .title {
    background-color: #2d72a4 !important;
    color: #fff;
  }
  #tophero {
    padding-top: 90px;
    background-position: 50% 50%;
    height: 600px;
  }
  #homebox2 .row .col:last-child {
    height: 150px;
  }
  #homebox3 .box {
    padding: 30px;
  }
  #accordion .card-body > div > div {
    padding-right: 5px;
    padding-left: 5px;
  }
  #accordion .card-body > div > div::before {
    float: unset;
    min-width: unset;
  }
  #accordion .card-body > div:nth-child(1) > div::before {
    display: block;
  }
  .modal-dialog .detailsdialog .images .mainimage {
    height: 350px;
  }
  .modal-dialog .detailsdialog .images .iconimages .iconimage {
    height: 60px;
    width: 90px;
  }
  .modal-dialog .detailsdialog .nav-tabs .nav-item .nav-link {
    margin-right: 0.5rem;
    font-size: 0.85rem !important;
  }
  #homebox3 .text1 {
    font-size: 3rem;
    padding-top: 50px;
  }
  #homebox3 .text2 {
    font-size: 1.5rem;
  }
  #homebox3 .text3 {
    font-size: 1.125rem;
    padding-top: 10px;
  }
  .pagination .page-item .page-link {
    font-size: 0.75rem;
  }
}
@media (min-width: 768px) {
  .padding-top > div[id=page-index] {
    padding-right: 0;
    padding-left: 0;
  }
  .padding-top > div[id^=page-]:not([data-path=pages]) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .homeboxproduct .row .col .img {
    height: 150px;
  }
  .homeboxproduct .row .col .txt1 {
    font-size: 1.6rem;
  }
  .homeboxproduct .row .col .txt3 {
    font-size: 1.3rem;
  }
  .homeboxproduct .row:hover .title {
    background-color: #2d72a4 !important;
    color: #fff;
  }
  .homeboxproduct .row:hover .txt3 {
    font-size: 1.5rem;
  }
  #tophero {
    padding-top: 90px;
    background-position: 50% 50%;
  }
  #homebox1 .homebox1 .textbox {
    padding-left: 25px;
    padding-top: 5px;
  }
  #homebox1 .homebox1 .textbox h5 {
    font-size: 1.25rem;
  }
  #homebox1 .homebox1 .textbox p {
    font-size: 1.15rem;
  }
  #homebox1 .homebox1 .svgicon {
    height: 65px;
    width: 65px;
    padding: 15px;
  }
  #homebox2 .row .col:first-child {
    height: 300px;
  }
  #homebox2 .row .col:first-child div {
    top: 10%;
    left: 5%;
    bottom: 10%;
    right: 0px;
    font-size: 2.2rem;
    letter-spacing: 5px;
    line-height: 1;
    padding-top: 40px;
    padding-left: 40px;
  }
  #homebox2 .row .col:last-child {
    height: 300px;
  }
  #homebox2 .row .col:last-child div {
    top: 10%;
    left: 0%;
    bottom: 10%;
    right: 10px;
    font-size: 1.2rem;
    letter-spacing: 6px;
    line-height: 1.1;
    padding-top: 20px;
    padding-left: 20px;
  }
  #homebox3 .box {
    padding: 40px;
  }
  #accordion .card-body > div > div {
    padding-right: 5px;
    padding-left: 5px;
  }
  #accordion .card-body > div:nth-child(1) {
    display: flex;
  }
  #accordion .card-body > div > div::before {
    display: none;
  }
  #accordion .card-body > div:nth-child(1) > div::before {
    display: block;
  }
  .modal-dialog .detailsdialog .images .mainimage {
    height: 250px;
  }
  .modal-dialog .detailsdialog .nav-tabs .nav-item .nav-link {
    margin-right: 1rem;
    font-size: 0.9rem !important;
  }
  #homebox3 .text1 {
    font-size: 3.5rem;
    padding-top: 50px;
  }
  #homebox3 .text2 {
    font-size: 1.5rem;
  }
  #homebox3 .text3 {
    font-size: 1.125rem;
    padding-top: 10px;
  }
  .modal .modal-dialog .detailsdialog .tabs {
    min-height: 600px;
  }
  .modal .modal-dialog .detailsdialog .tab-content .tab-pane#ex1-tabs-3 .row {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 500px;
  }
  .pagination .page-item .page-link {
    font-size: 0.9rem;
  }
}
@media (min-width: 992px) {
  .padding-top > div[id=page-index] {
    padding-right: 0;
    padding-left: 0;
  }
  .padding-top > div[id^=page-]:not([data-path=pages]) {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .homeboxproduct .row .col .img {
    height: 200px;
  }
  .homeboxproduct .row .col .txt1 {
    font-size: 1.875rem;
  }
  .homeboxproduct .row .col .txt3 {
    font-size: 1.6rem;
  }
  .homeboxproduct .row:hover .title {
    background-color: #2d72a4 !important;
    color: #fff;
  }
  .homeboxproduct .row:hover .txt3 {
    font-size: 1.8rem;
  }
  .navbar.navbar-expand-lg {
    background-color: transparent !important;
    background-image: none !important;
  }
  .navbar.applybgdark.bg-dark {
    background-color: #000000 !important;
  }
  #tophero {
    padding-top: 300px;
    background-position: 50% 50%;
  }
  #homebox2 .row .col:first-child {
    height: 500px;
  }
  #homebox2 .row .col:first-child div {
    top: 20%;
    left: 10%;
    bottom: 20%;
    right: 0px;
    font-size: 3rem;
    padding-top: 50px;
    padding-left: 50px;
  }
  #homebox2 .row .col:last-child {
    height: 500px;
  }
  #homebox2 .row .col:last-child div {
    top: 20%;
    left: 0%;
    bottom: 20%;
    right: 10px;
    font-size: 1.7rem;
    padding-top: 50px;
    padding-left: 50px;
  }
  #homebox2 .row .col:last-child div a {
    border-width: 2px;
    letter-spacing: 2px;
    margin: 10px auto 0;
    padding: 10px 30px;
  }
  #homebox3 .box {
    padding: 50px;
  }
  #accordion .card-body > div > div {
    padding-right: 5px;
    padding-left: 5px;
  }
  #accordion .card-body > div:nth-child(1) > div::before {
    display: block;
  }
  .modal-dialog .detailsdialog .images .mainimage {
    height: 300px;
  }
  .modal-dialog .detailsdialog .images .iconimages .iconimage {
    height: 75px;
    width: 100px;
  }
  .modal-dialog .detailsdialog .nav-tabs .nav-item .nav-link {
    margin-right: 1.5rem;
    font-size: 1rem !important;
  }
  #homebox3 .text1 {
    margin-right: 4.5rem;
    padding-top: 50px;
  }
  #homebox3 .text2 {
    font-size: 1.5rem;
  }
  #homebox3 .text3 {
    font-size: 1.125rem;
    padding-top: 10px;
  }
  .pagination .page-item .page-link {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .padding-top > div[id=page-index] {
    padding-right: 0;
    padding-left: 0;
  }
  .padding-top > div[id^=page-]:not([data-path=pages]) {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .homeboxproduct .row:hover .title {
    background-color: #2d72a4 !important;
    color: #fff;
  }
  #tophero {
    padding-top: 300px;
    background-position: 50% 50%;
  }
  #accordion .card-body > div > div {
    padding-right: 5px;
    padding-left: 5px;
  }
  #accordion .card-body > div:nth-child(1) > div::before {
    display: block;
  }
  #homebox3 .text1 {
    padding-top: 50px;
  }
  #homebox3 .text2 {
    font-size: 1.5rem;
  }
  #homebox3 .text3 {
    font-size: 1.125rem;
    padding-top: 10px;
  }
}
.product-container .product-details {
  margin-left: 0px;
}

.modal .modal-dialog {
  max-width: 1200px;
  margin: 1.75rem auto;
}
.modal .modal-dialog .detailsdialog .images .mainimage {
  background-position: center;
  background-size: cover;
  border: 1px #bababa solid;
  margin: 5px;
}
.modal .modal-dialog .detailsdialog .images .iconimages .iconimage {
  background-position: center;
  background-size: cover;
  float: left;
  margin: 5px;
  border: 1px #E2E6E9 solid;
}
.modal .modal-dialog .detailsdialog .tabs .nav-tabs .nav-item .nav-link {
  text-decoration: none;
  line-height: 1;
  font-size: 14px;
}
.modal .modal-dialog .detailsdialog .tabs .nav-tabs .nav-link:hover {
  color: #2d72a4;
}
.modal .modal-dialog .detailsdialog .tabs .nav-tabs .nav-item.show .nav-link, .modal .modal-dialog .detailsdialog .tabs .nav-tabs .nav-link.active {
  color: #2d72a4;
  border-bottom: 2px solid #2d72a4;
}
.modal .modal-dialog .detailsdialog .tab-content .tab-pane {
  font-size: 15px;
}
.modal .modal-dialog .detailsdialog .tab-content .tab-pane .bold {
  font-weight: 700;
}
.modal .modal-dialog .detailsdialog .tab-content .tab-pane .flexorder {
  display: flex;
  flex-direction: column;
}
.modal .modal-dialog .detailsdialog .tab-content .tab-pane .flexorder > div {
  border-bottom: solid 1px #eee;
  padding: 5px;
}
.modal .modal-dialog .detailsdialog .tab-content .tab-pane .iconimage {
  background-position: center;
  background-size: cover;
  float: left;
  margin: 5px;
  height: 150px;
  width: 100%;
}
.modal .modal-dialog .detailsdialog .tab-content .tab-pane .txt1 {
  text-align: center;
  font-size: 14px;
}
.modal .modal-dialog .detailsdialog .tab-content .tab-pane .txt2 {
  color: rgb(116, 116, 116);
  text-align: center;
  font-size: 14px;
}
.modal .modal-dialog .detailsdialog .tab-content .tab-pane#ex1-tabs-3 hr {
  clear: both;
  border: solid 1px #2d72a4;
}
.modal .modal-dialog .detailsdialog .tab-content .tab-pane#ex1-tabs-3 .row > div {
  padding-bottom: 20px;
}
.modal .modal-dialog .detailsdialog .tab-content .tab-pane#ex1-tabs-4 a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
}
.modal .modal-dialog .detailsdialog .tab-content .tab-pane#ex1-tabs-4 a svg {
  color: #2d72a4;
}
.modal .modal-header .modal-title {
  color: #2d72a4;
  font-size: 2rem;
}

.variantcolor {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  float: left;
}